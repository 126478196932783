import { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import SvgLogo3D from "./Logo3D";

export default function NavMenu() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const menuRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [menuRef]);

  return (
    <nav>
      <div className="menu-container">
        <a href="/" className="nav-brand">
          <SvgLogo3D width={32} />
        </a>
        <button className="menu-btn" onClick={toggleMenu}>
          {showMenu ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </button>
        <div className={`menu ${showMenu ? "show" : ""}`}>
          <ul>
            <li>
              <a href="/">About</a>
            </li>
            <li>
              <a href="/gallery">Gallery</a>
            </li>
            {isAuthenticated && (
              <li>
                <a href="/profile">Profile</a>
              </li>
            )}

            <li>
              <a href="/legal">Legal</a>
            </li>
          </ul>
          <ul>
            {isAuthenticated ? (
              <li>
                <LogoutButton />
              </li>
            ) : (
              <li>
                <LoginButton />
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
