import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SvgLogo3D from "../components/Logo3D";
import InvoiceBar from "../components/InvoiceBar";
import Footer from "../components/Footer";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    if (user) {
      const Stripe = require("stripe");
      const stripe = Stripe(process.env.REACT_APP_STRIPE_SECRET);

      const retrieveInvoices = async () => {
        const stripeCustomer = await stripe.customers.search({
          query: `email~"${user.email}"`,
          limit: 1,
        });
        const retrievedInvoices = await stripe.invoices.list({
          limit: 10,
          customer: stripeCustomer.data[0].id,
        });
        setInvoices(retrievedInvoices.data);
      };
      retrieveInvoices();
    }
  }, [user]);

  if (isLoading) {
    return (
      <div className="preloader">
        <SvgLogo3D className="pulse" />
      </div>
    );
  }

  return isAuthenticated ? (
    <div className="static-page">
      <h1 className="section-heading">My Profile</h1>
      <div className="profile-card">
        <img src={user.picture} alt={user.name} className="avatar" />
        <div>
          <h2>{user.name}</h2>
          <p>{user.email}</p>
        </div>
      </div>
      <h1 className="section-heading">My Invoices</h1>
      {invoices.length > 0 ? (
        invoices.map((invoice) => {
          if (
            invoice.status === "open" ||
            invoice.status === "paid" ||
            invoice.status === "void"
          ) {
            return (
              <>
                <InvoiceBar key={invoice.id} invoice={invoice} />
              </>
            );
          } else {
            return null;
          }
        })
      ) : (
        <h3 style={{ textAlign: "center" }}>You're all caught up!</h3>
      )}
      <Footer />
    </div>
  ) : (
    <h1>Please log in</h1>
  );
};

export default Profile;
