import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { user, logout } = useAuth0();

  return (
    <button
      className="auth-button"
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      <div
        className="avatar-icon"
        style={{ backgroundImage: `url(${user.picture})` }}
      />
      Log Out
    </button>
  );
};

export default LogoutButton;
