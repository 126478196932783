import React from "react";
import "../App.css";
import Showcase from "../components/Showcase";
import spaceNeedle from "../assets/space-needle.png";
import lotus from "../assets/lotus.png";
import horizon from "../assets/horizon.png";
import sbDinnerMenu from "../assets/sb-dinner-menu.png";
import grapes from "../assets/grapes.png";
import Footer from "../components/Footer";

const galleryImages = [spaceNeedle, , grapes, lotus, horizon, sbDinnerMenu];

export default function Gallery() {
  return (
    <>
      <Showcase heading="Logo & Branding Samples" images={galleryImages} />
      <Footer />
    </>
  );
}
