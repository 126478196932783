import * as React from "react";
const SvgArrowGradient = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 700 700"
    {...props}
  >
    <script xlinkHref="https://cdn.jsdelivr.net/npm/@svgdotjs/svg.js@3.0/dist/svg.min.js" />
    <defs>
      <linearGradient id="a">
        <stop
          offset={0.237}
          style={{
            stopColor: "#589dca",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#582e80",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="b"
        x1={349.928}
        x2={341.429}
        y1={475.464}
        y2={65.967}
        gradientUnits="userSpaceOnUse"
      />
    </defs>
    <g
      style={{
        fillOpacity: 1,
        fill: "url(#b)",
      }}
    >
      <path
        d="M515.55 213.5c-1.398-3.148-4.55-5.25-8.05-5.25h-85.75v-133c0-4.898-3.852-8.75-8.75-8.75H287c-4.9 0-8.75 3.852-8.75 8.75v133H192.5c-3.5 0-6.65 2.102-8.052 5.25-1.398 3.148-.699 6.648 1.75 9.45l157.5 173.25c1.403 1.75 3.852 2.8 6.301 2.8 2.45 0 4.899-1.05 6.301-2.8l157.5-173.25c2.45-2.802 3.148-6.302 1.75-9.45z"
        style={{
          fillOpacity: 1,
          fill: "url(#b)",
        }}
      />
      <path
        d="m198.8 305.55-12.78-13.002.178 6.333 157.5 173.25c1.402 1.75 3.685 3.714 6.135 3.714 2.449 0 5.064-1.964 6.467-3.714l157.5-173.25-.148-5.974-12.454 12.643-151.2 166.25z"
        style={{
          fill: "url(#b)",
          fillOpacity: 1,
        }}
      />
    </g>
  </svg>
);
export default SvgArrowGradient;
