import React from "react";
import { InlineWidget } from "react-calendly";
import Footer from "../components/Footer";

const Booking = () => {
  return (
    <div className="static-page">
      <h1 className="section-heading">We're looking forward to meeting you!</h1>
      <p className="section-paragraph">
        Thank you for your interest in our services! We are happy to offer you a
        free consultation with one of our experts to get to know you and your
        brand, and better determine your needs. Book an appointment below to
        chat about your needs and get a no-obligation quote.
      </p>
      <InlineWidget
        url="https://calendly.com/pixel-perfect-design"
        text="Book an Appointment"
        styles={{
          minWidth: "280px",
          height: "630px",
          backgroundColor: "#081A39AA",
          marginLeft: "-1rem",
        }}
      />
      <Footer />
    </div>
  );
};

export default Booking;
