import React from "react";
import Footer from "../components/Footer";

function NotFound() {
  return (
    <div className="static-page">
      <div style={{ margin: "8rem auto" }}>
        <h1>404 - Not Found</h1>
        <p>The page you are looking for does not exist.</p>
        <a href="/">Take me home.</a>
      </div>
      <Footer />
    </div>
  );
}

export default NotFound;
