export default function Footer() {
  return (
    <footer>
      <div className="footer-content footer-content-tl">
        &copy; {new Date().getFullYear()} Pixel Perfect Design and Development,
        LLC
      </div>
      <div className="footer-content footer-content-br">
        <a href="/">About</a>
        <a href="/gallery">Gallery</a>
        <a href="/booking">Book an Appointment</a>
        <a href="/legal">Legal</a>
      </div>
    </footer>
  );
}
