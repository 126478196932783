import React from "react";
import Footer from "../components/Footer";

function ServerError() {
  return (
    <div className="static-page">
      <div style={{ margin: "8rem auto" }}>
        <h1>500 - Internal Server Error</h1>
        <p>Ouch! Something went wrong there. Let's try reloading the site.</p>
        <a href="/">Take me home.</a>
      </div>
      <Footer />
    </div>
  );
}

export default ServerError;
