import React from "react";
import { Helmet } from "react-helmet";

const Head = () => {
  return (
    <Helmet>
      <title>Pixel Perfect Design and Development, LLC</title>
      <meta
        name="description"
        content="We provide custom web design and branding services for small and medium-sized businesses."
      />
      <meta
        name="keywords"
        content="web design, web development, custom, small business, medium business, pixel perfect"
      />
      <meta name="author" content="Pixel Perfect Design and Development, LLC" />
      <link rel="canonical" href="https://www.pixelperfectdesign.io/" />
      <meta
        property="og:title"
        content="Pixel Perfect Design and Development, LLC"
      />
      <meta
        property="og:description"
        content="We provide custom web design and branding services for small and medium-sized businesses."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.pixelperfectdesign.io/" />
      <meta property="og:image" content="/images/logo.png" />
      <meta
        property="og:image:alt"
        content="Pixel Perfect Design and Development, LLC"
      />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="400" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Pixel Perfect Design and Development, LLC"
      />
      <meta
        name="twitter:description"
        content="We provide custom web design and development services for small and medium-sized businesses."
      />
      <meta name="twitter:image" content="/images/logo.png" />
      <meta
        name="twitter:image:alt"
        content="Pixel Perfect Design and Development, LLC"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
  );
};

export default Head;
