import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const StripeManager = () => {
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    const Stripe = require("stripe");
    const stripe = Stripe(process.env.REACT_APP_STRIPE_SECRET);
    const createStripeCustomer = async () => {
      if (user) {
        const customer = await stripe.customers.search({
          query: `email~"${user.email}"`,
          limit: 1,
        });
        if (customer.data.length === 0) {
          await stripe.customers.create({
            description: "Created by API on first-time login",
            email: user.email,
            name: user.name,
          });
        } else {
          // We found a Stripe customer that matches our authenticated oid user by email
          return;
        }
      }
    };
    createStripeCustomer();
  }, [user, isAuthenticated]);

  return null;
};

export default StripeManager;
