import React from "react";
import Footer from "../components/Footer";

export default function Legal() {
  return (
    <div className="static-page">
      <h1 className="section-heading">Legal Info</h1>
      <h2>Privacy Policy </h2>
      <p>
        This Privacy Policy governs the manner in which Pixel Perfect Design and
        Development, LLC collects, uses, maintains, and discloses information
        collected from users of the{" "}
        <a href="https://www.pixelperfectdesign.io">Website</a> (the "Site").
        This privacy policy applies to the Site and all products and services
        offered by Pixel Perfect Design and Development, LLC.
      </p>{" "}
      <h2>Personally Identifiable Information</h2>
      <p>
        Pixel Perfect Design and Development, LLC may collect personal
        identification information from users in a variety of ways, including,
        but not limited to, when users visit the Site, register on the Site,
        place an order, subscribe to the newsletter, respond to a survey, fill
        out a form, and in connection with other activities, services, features,
        or resources we make available on our Site. Users may be asked for, as
        appropriate, name, email address, mailing address, phone number, credit
        card information. Users may, however, visit our Site anonymously. We
        will collect personal identification information from users only if they
        voluntarily submit such information to us. Users can always refuse to
        supply personally identification information, except that it may prevent
        them from engaging in certain Site-related activities.
      </p>{" "}
      <h2>Non-personally Identifiable Information</h2>
      <p>
        We may collect non-personal identification information about users
        whenever they interact with our Site. Non-personal identification
        information may include the browser name, the type of computer, and
        technical information about users’ means of connection to our Site, such
        as the operating system and the Internet service providers utilized, and
        other similar information.
      </p>{" "}
      <h2>Web Browser Cookies</h2>
      <p>
        Our Site may use "cookies" to enhance user experience. Users' web
        browsers place cookies on their hard drives for record-keeping purposes
        and sometimes to track information about them. Users may choose to set
        their web browser to refuse cookies or to alert them when cookies are
        being sent. If they do so, note that some parts of the Site may not
        function properly.
      </p>{" "}
      <h2>How we use collected information</h2>
      <p>
        Pixel Perfect Design and Development, LLC may collect and use users’
        personal information for the following purposes:
      </p>{" "}
      <ul>
        <li>
          To improve customer service: Information you provide helps us respond
          to your customer service requests and support needs more efficiently.
        </li>
        <li>
          To personalize user experience: We may use information in the
          aggregate to understand how our users as a group use the services and
          resources provided on our Site.
        </li>
        <li>
          To improve our Site: We may use feedback you provide to improve our
          products and services.
        </li>
        <li>
          To process payments: We may use the information users provide about
          themselves when placing an order only to provide service to that
          order. We do not share this information with outside parties except to
          the extent necessary to provide the service.
        </li>
        <li>
          To send periodic emails: We may use the email address to send them
          information and updates pertaining to their order. It may also be used
          to respond to their inquiries, questions, and/or other requests.
        </li>
      </ul>
      <h2>How We Protect Your Information</h2>
      <p>
        We adopt appropriate data collection, storage, and processing practices,
        and security measures to protect against unauthorized access,
        alteration, disclosure, or destruction of your personal information,
        username, password, transaction information, and data stored on our
        Site.
      </p>{" "}
      <h2>Sharing Your Personal Information</h2>
      <p>
        We do not sell, trade, or rent users’ personal identification
        information to others. We may share generic aggregated demographic
        information not linked to any personal identification information
        regarding visitors and users with our business partners, trusted
        affiliates, and advertisers for the purposes outlined above.
      </p>{" "}
      <h2>Third-party Services</h2>
      <p>
        Our website may contain links to other websites or services that are not
        owned or controlled by us. We have no control over, and assume no
        responsibility for the content, privacy policies, or practices of any
        third-party websites or services. You acknowledge and agree that we
        shall not be responsible or liable, directly or indirectly, for any
        damage or loss caused or alleged to be caused by or in connection with
        the use of or reliance on any such content, goods, or services available
        on or through any such websites or services.
      </p>{" "}
      <h2>Data Retention</h2>
      <p>
        We will retain your Personal Information only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use your Personal Information to the extent necessary to
        comply with our legal obligations (for example, if we are required to
        retain your data to comply with applicable laws), resolve disputes, and
        enforce our legal agreements and policies.
      </p>{" "}
      <h2>Security</h2>
      <p>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet or method of
        electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security.
      </p>{" "}
      <h2>International Transfer</h2>
      <p>
        Your information, including Personal Information, may be transferred to
        and maintained on computers located outside of your state, province,
        country, or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction.
      </p>{" "}
      <p>
        If you are located outside the United States and choose to provide
        information to us, please note that we transfer the information,
        including Personal Information, to the United States and process it
        there.
      </p>{" "}
      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>{" "}
      <h2>Your Data Protection Rights Under GDPR</h2>
      <p>
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights. We aim to take reasonable steps to allow
        you to correct, amend, delete, or limit the use of your Personal
        Information.
      </p>{" "}
      <p>
        If you wish to be informed what Personal Information we hold about you
        and if you want it to be removed from our systems, please contact us at
        the email address provided below.
      </p>{" "}
      <p>
        In certain circumstances, you have the following data protection rights:
      </p>
      <ul>
        <li>
          The right to access, update, or to delete the information we have on
          you.
        </li>
        <li>The right of rectification.</li>
        <li>The right to object.</li>
        <li>The right of restriction.</li>
        <li>The right to data portability.</li>
        <li>The right to withdraw consent</li>
      </ul>
      <h2>Changes to this privacy policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>{" "}
      <p>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the "effective date"
        at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>{" "}
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at{" "}
        <a href="mailto:info@pixelperfectdesign.io">
          hello@pixelperfectdesign.io
        </a>
        .
      </p>
      <Footer />
    </div>
  );
}
