import React from "react";
import withDimensions from "./withDimensions";

function Showcase({ ...props }) {
  const { width } = props;
  var count = 0;

  return (
    <>
      {width > 1280 ? (
        <div className="external">
          <div className="horizontal-scroll-wrapper">
            {props.images.map((i, index) => {
              let classList = [
                "slower img-sm",
                "faster",
                "faster2 img-lg",
                "slower slower-down",
                "img-sm",
                "slower",
                "faster",
                "slower2 img-lg",
                "img-sm",
                "slower-down",
                "faster",
              ];
              return (
                <div className={`img-wrapper ${classList[index % 11]}`}>
                  <img src={i.toString()} key={i.toString()} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <section className="showcase-section">
          <h1 className="section-heading">{props.heading}</h1>
          <div className="showcase-list">
            {props.images.map((i) => {
              return <img src={i.toString()} key={i.toString()} />;
            })}
          </div>
        </section>
      )}
    </>
  );
}

export default withDimensions(Showcase);
