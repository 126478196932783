import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import StripeManager from "./components/StripeManager";
import Head from "./components/Head";
import NavMenu from "./components/NavMenu";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Booking from "./pages/Booking";
import Legal from "./pages/Legal";
import NotFound from "./pages/NotFound";
import ServerError from "./pages/ServerError";
import "./App.css";
import Profile from "./pages/Profile";

export default function App() {
  return (
    <Auth0Provider
      domain="dev-zp1gfhhh3w6dtw0k.us.auth0.com"
      clientId="5Gg6QlK4UQ2kURtzAIcxHwjd5pWlJHBu"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
    >
      <StripeManager />
      <Router>
        <Head />
        <header>
          <NavMenu />
        </header>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/legal" component={Legal} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/profile" component={Profile} />
          <Route path="/booking" component={Booking} />
          <Route path="/404" component={NotFound} />
          <Route path="/500" component={ServerError} />
          <Route component={NotFound} />
        </Switch>
        {/*Use the below code snippet to provide real time updates to the live chat plugin without the need of copying and paste each time to your website when changes are made via PBX*/}
        <call-us-selector
          className="call-us"
          phonesystem-url="https://1257.3cx.cloud"
          party="danieljustus"
        />
      </Router>
    </Auth0Provider>
  );
}
