import { useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faDollar } from "@fortawesome/free-solid-svg-icons";

const InvoiceBar = ({ invoice }) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const renderLineItems = () => {
    const { data } = invoice.lines;
    if (!showDetails || !data) {
      return null;
    }
    return (
      <div className="line-items">
        {data.map((line) => (
          <div key={line.id} className="line-item">
            <div className="line-description">{line.description}</div>
            <div className="line-dots">. . . . . . . . . . . </div>
            <div className="line-amount">${(line.amount / 100).toFixed(2)}</div>
          </div>
        ))}
      </div>
    );
  };

  const formatTotal = () => {
    const total = invoice.total / 100;
    return total.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="invoice-bar">
      <div className="invoice-info">
        <div>
          <strong>Invoice Date:</strong>
          <br />
          {moment.unix(invoice.period_end).format("MM/DD/YYYY")}
        </div>
        <div>
          <strong>Status: </strong>
          {invoice.status === "paid" && (
            <div className="paid badge">
              <span>Paid</span>
            </div>
          )}
          {invoice.status === "open" && (
            <div className="open badge">
              <span>Open</span>
            </div>
          )}
          {invoice.status === "void" && (
            <div className="void badge">
              <span>Void</span>
            </div>
          )}
        </div>
        <div className="invoice-actions">
          <a href={invoice.invoice_pdf}>
            <FontAwesomeIcon icon={faDownload} />
          </a>
          {invoice.status === "open" && (
            <a href={invoice.hosted_invoice_url}>
              <FontAwesomeIcon icon={faDollar} />
            </a>
          )}
          {invoice.status === "paid" || invoice.status === "void" ? (
            <FontAwesomeIcon
              icon={faDollar}
              aria-disabled={true}
              style={{ color: "#88888", marginRight: "1rem" }}
            />
          ) : null}
          <div className="toggle-details" onClick={handleToggleDetails}>
            {showDetails ? "Hide Details" : "Show Details"}
            <span
              className={showDetails ? "chevron down" : "chevron up"}
            ></span>
          </div>
        </div>
      </div>
      {renderLineItems()}
      <div className="invoice-total">Total: {formatTotal()}</div>
    </div>
  );
};

export default InvoiceBar;
